<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="flex justify-content-between mb-4 table-label-search">
          <h3>Transactions</h3>
          <div>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="search" placeholder="Keyword Search" @input="onSearch" />
            </span>
          </div>

        </div>

        <DataTable
          :value="getTransactions" :lazy="true" :paginator="true" :first="getPagination?.from-1"
          :rows="getPagination?.per_page ?? 25"  ref="dt" dataKey="id"
          :totalRecords="getPagination.total" :loading="loading" @page="onPage($event)"
          @sort="onSort($event)" :sortField="sort" :sortOrder="order"
          responsiveLayout="scroll" :rowsPerPageOptions="[10,25,50]"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          :globalFilterFields="['status', 'method']" v-model:filters="filters" filterDisplay="row" @filter="onFilter($event, ['status', 'method'])"
        >
          <template #empty>
            Not found.
          </template>

          <!-- ID -->
          <Column field="id" header="ID" :sortable="true"/>

          <!-- Company Name -->
          <Column field="company" header="Company Name" :sortable="true"/>

          <!-- User Name -->
          <Column field="user" header="User Name" :sortable="true"/>

          <!-- Amount -->
          <Column field="amount" header="Amount" :sortable="true">
            <template #body="{data}">
              <span :class="'product-badge status-' + (data.amount >= 0 ? 'active' : 'inactive')">
                 {{data.amount >= 0 ? '+' + data.amount : data.amount}}
              </span>
            </template>
          </Column>

          <!-- Method -->
          <Column field="method" header="Method" placeholder="Any" :showFilterMenu="false" :sortable="true">
            <template #body="{data}">
              <span class="image-text">{{methodText(data.method).label.toUpperCase()}}</span>
            </template>
            <template #filter="{filterModel,filterCallback}">
              <MultiSelect
                v-model="filterModel.value" @change="filterCallback()" :options="methods"
                optionLabel="label" optionValue="id" placeholder="Any" class="p-column-filter"
                :showClear="true"
              >
                <template #option="slotProps">
                  <div class="p-multiselect-representative-option">
                    <span class="image-text">{{slotProps.option.label}}</span>
                  </div>
                </template>
              </MultiSelect>
            </template>
          </Column>

          <!-- Status -->
          <Column field="status" header="Status" placeholder="Any" :showFilterMenu="false" :sortable="true">
            <template #body="{data}">
              <span :class="'product-badge ' + (statusText(data.status).class)">
                {{statusText(data.status).label.toUpperCase()}}
              </span>
            </template>
            <template #filter="{filterModel,filterCallback}">
              <MultiSelect
                v-model="filterModel.value" @change="filterCallback()" :options="statuses"
                optionLabel="label" optionValue="id" placeholder="Any" class="p-column-filter"
                :showClear="true"
              >
                <template #option="slotProps">
                  <div class="p-multiselect-representative-option">
                    <span :class="'product-badge ' + (slotProps.option.class)">
                      {{slotProps.option.label}}
                    </span>
                  </div>
                </template>
              </MultiSelect>
            </template>
          </Column>

          <!-- Date -->
          <Column field="complete_date" header="Date" :sortable="true"/>

        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import store from "@store";
import toastParamBuilder from "@/builders/ToastParamBuilder";
import dataTable from "@/mixins/dataTable";
import {FilterMatchMode} from "primevue/api";

export default {
  mixins: [dataTable],

  data() {
    return {
      methods: [
        {id: 1, label: 'GO_CHYP'},
        {id: 2, label: 'ZELLE'},
        {id: 3, label: 'ORDER'},
      ],
      statuses: [
        {id: 1, label: 'COMPLETED', class: 'status-success'},
        {id: 2, label: 'REJECTED', class: 'status-rejected'},
      ],
      filters: {
        method: {value: null, matchMode: FilterMatchMode.EQUALS},
        status: {value: null, matchMode: FilterMatchMode.EQUALS},
      },
    }
  },

  computed: {
    ...mapGetters({
      getTransactions: "transaction/getTransactions",
      getPagination: "transaction/getPagination",
    }),
    statusText() {
      return status => this.statuses?.find(i => i.id === status);
    },
    methodText() {
      return method => this.methods?.find(i => i.id === method);
    },
  },

  async beforeRouteEnter(to, from, next) {
    await store.dispatch('transaction/transactions', to.query)
      .then(() => {
        next((vm) => {
          if (vm.$route.query?.method) {
            vm.filters.method.value = vm.methods.filter((e) => {
              return vm.$route.query?.method.includes(e.id.toString());
            }).map(e => e.id);
          }
          if (vm.$route.query?.status) {
            vm.filters.status.value = vm.statuses.filter((e) => {
              return vm.$route.query?.status.includes(e.id.toString());
            }).map(e => e.id);
          }
        });
      })
      .catch((error) => {
        next((vm) => {
          vm.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
        });
      });
  },

  async beforeRouteUpdate(to) {
    await store.dispatch('transaction/transactions', to.query)
      .catch((error) => {
        this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
      });
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/demo/badges.scss';
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
</style>
